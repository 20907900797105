
export default {
  bannerList: [
    {
      bannerImg: '/static/image/home_new/banner1.png',
      title: '以科技驱动高效供应链物流',
      text: '打造全场景的数智物流解决方案',
      index: 1
    },
    {
      bannerImg: '/static/image/home_new/banner2.png',
      title: '为企业构建全景数智物流供应链体系',
      text: '推进业务数字化、作业数字化、运营数字化',
      index: 2
    },
    {
      bannerImg: '/static/image/home_new/banner3.png',
      applyUse: '申请试用',
      index: 3
    },
    {
      bannerImg: '/static/image/home_new/banner4.png',
      title: '务实奋进丨求是创新丨开放融合丨服务至上',
      text: '为企业提供支撑、为伙伴赋能发展，为行业做出贡献',
      index: 4
    },
  ],
  floor1: {
    title: '一体化数智物流技术解决方案',
    subTitle: '助力客户打造高效响应、柔性智能的供应链物流体系',
    text: '九州云智聚焦泛大健康领域，在理解客户战略基础上，应用数据分析模型，进行结构、模式、流程、资源一体化统筹规划设计，满足业务、作业、运营、技术一体化应用场景需求。在众多行业拥有丰富的业务沉淀和项目经验，以专业的解决方案赋能企业数智化供应链物流升级，构建运营体系、打造精益现场，赋能企业低成本、高质量发展，帮助企业降本增效、管理优化。',
    listData: [
      {
        icon: '/static/image/home_new/icon_4_1.png',
        text1: '30年',
        text2: '发展与沉淀'
      },
      {
        icon: '/static/image/home_new/icon_4_2.png',
        text1: '31家',
        text2: '分支机构'
      },
      {
        icon: '/static/image/home_new/icon_4_3.png',
        text1: '127',
        text2: '资质及行业奖项'
      },
      {
        icon: '/static/image/home_new/icon_4_4.png',
        text1: '300+',
        text2: '行业案例'
      },
    ],
    picListData: [
      {
        img1: '/static/image/home_new/icon_resolve_1_1.png',
        img2: '/static/image/home_new/icon_resolve_1.png',
        icon: '/static/image/home_new/resolve_icon1.png',
        title: '医药生产',
        path: '/resolvePlan/1',
        text: '从采购到成品出库标准化作业流程管理，全链路数字化系统应用覆盖，全场景自动化智能装备适配，全方位GSP/GMP质量管理与作业流程融合，打造高智能、高效率、高吞吐的医药生产解决方案。'
      },
      {
        img1: '/static/image/home_new/icon_resolve_2_1.png',
        img2: '/static/image/home_new/icon_resolve_2.png',
        icon: '/static/image/home_new/resolve_icon2.png',
        title: '医药流通',
        path: '/resolvePlan/2',
        text: '支持多仓多货主平台化模式，高度可配置、高度兼容多业态应用场景，全流程智能装备配套，高度自动化生产赋能，全过程应用系统覆盖、全数字化运营，实现上下游互联互通、标准运营、精益管理、智慧调度、全流程协同、全方位质量管理。'
      },
      {
        img1: '/static/image/home_new/icon_resolve_3_1.png',
        img2: '/static/image/home_new/icon_resolve_3.png',
        icon: '/static/image/home_new/resolve_icon3.png',
        title: '医药连锁',
        path: '/resolvePlan/3',
        text: '从订单接收到交接签收全流程应用覆盖，订单全生命周期的实时跟踪，精细物流费用核算、全可视化运营管理。高度智能化装备，实现具备柔性的峰值应对能力。质量及品控与作业流程融合，全过程闭环管理。'
      },
      {
        img1: '/static/image/home_new/icon_resolve_6_1.png',
        img2: '/static/image/home_new/icon_resolve_6.png',
        icon: '/static/image/home_new/resolve_icon6.png',
        title: '医药三方',
        path: '/resolvePlan/4',
        text: '聚焦3PL“仓、运、配”环节，以业务管理系统进行物流资源、网络构建与产品服务管理，精益的计划管控，智能的路由调度、拆合单及任务打包与分发与多主体的控制协同。实现精细化计费管理、多业态仓储管理、全流程配送管理、智能化作业赋能、可视化实时管控。'
      },
      {
        img1: '/static/image/home_new/icon_resolve_4_1.png',
        img2: '/static/image/home_new/icon_resolve_4.png',
        icon: '/static/image/home_new/resolve_icon4.png',
        title: '食品生鲜',
        path: '/resolvePlan/5',
        text: '全链作业一体化协同运营，从田园到舌尖全链数字化实时管控。从下游请货到上游备货标准化作业流程管理，全系统应用覆盖，标准化、精细化分类管理，减少商品滞留和损耗。利用冷库穿梭车、多温层AGV、自动电子秤等高度智能化装备实现高效率与高吞吐作业。'
      },
      {
        img1: '/static/image/home_new/icon_resolve_5_1.png',
        img2: '/static/image/home_new/icon_resolve_5.png',
        icon: '/static/image/home_new/resolve_icon5.png',
        title: '酒水饮料',
        path: '/resolvePlan/6',
        text: '依托全网络仓干配能力，通过数字化实时管控、智能化作业赋能，全链路可视化监督，提供全渠道触达、低成本、稳定的物流服务，实现多渠道一盘货库存管理，并衔接多销售渠道的信息管理体系，全过程数字化溯源跟踪，确保订单准确交付，柔性调度精准响应。'
      },
      {
        img1: '/static/image/home_new/icon_resolve_7_1.png',
        img2: '/static/image/home_new/icon_resolve_7.png',
        icon: '/static/image/home_new/resolve_icon7.png',
        title: '美妆日化',
        path: '/resolvePlan/7',
        text: '多仓协同管理，智能化调度，全流程策略可配置，实现精细化货位管理，合理库存分配， 高效率作业、上下流程环节协同，订单同步性等智能化控制。'
      },
    ]
  },
  floorTwoData: {
    // des: [{ title: "物流中心集成" }, { title: "供应链物流软件" }, { title: "智能物流装备" }, { title: "物流运营管理" }],
    mainContent: [
      {
        src: '/static/image/home_new/home_product_1.png',
        t1: "物流中心集成",
        icon: "/static/image/home_new/f2_icon_1.png",
        iconTit: '物流中心集成',
        t2: '在理解客户战略的基础上，应用数据分析模型，进行结构、模式、流程、资源一体化统筹规划设计，提供“规划、软件、设备、运营”的一站式集成交付服务。',
        t2List: ['一体化统筹规划设计', '一站式集成交付服务', '高柔性、高效率、高扩展、高吞吐'],
        goMore: 1,
      },
      {
        src: '/static/image/home_new/home_product_2.png',
        t1: "供应链物流软件",
        iconTit: '供应链物流软件',
        icon: "/static/image/home_new/f2_icon_1.png",
        t2: '拥有自主研发的OFS协同物流控制塔、WMS仓储管理系统, EIC设备管理系统、TMS运输管理系统、BMS计费管理系统等，全链功能的物流信息平台，可为伙伴提供全场景的系统解决方案，同时提供一套统一技术开发底座，赋予企业自主研发扩展的能力。',
        t2List: ['自主研发的OFS协同系统', '全链路功能平台', '企业自主研发扩展'],
        goMore: 2,
      },
      {
        src: '/static/image/home_new/home_product_3.png',
        t1: "智能物流装备",
        iconTit: '智能物流装备',
        icon: "/static/image/home_new/f2_icon_1.png",
        t2: '具备设备定制化开发的能力，自主研发了适应不同作业环境的箱式穿梭车、托盘穿梭车、冷冻穿梭车、提升机、分拨站等系列智能装备产品。',
        t2List: ['定制化开发', '多类型智能装备', '高柔性、高效率、高扩展、高吞吐'],
        goMore: 3,
      },
      {
        src: '/static/image/home_new/home_product_4.png',
        t1: "物流运营管理",
        iconTit: '物流运营管理',
        icon: "/static/image/home_new/f2_icon_1.png",
        t2: '为行业伙伴构建运营体系、打造精益现场、提供专业化的数智运营决策，支持企业低成本、高质量、可持续发展。',
        t2List: ['精益现场', '数智化运营', '低成本、高质量、可持续发展'],
        goMore: 4,
      },
    ],
  },
  floorFiveData: [
    {

      title1: "九州通加码投入升级冷链物流，疫…",
      title2: "ORDER 6月17日，庆铃&九州通 健康送达千家万户—庆铃五十铃都市冰峰批量交车仪式在九州通医药集团东西湖医药…",
      text: "人民网",
      time: '2023.06.06'
    }, {

      title1: "九州通加码投入升级冷链物流，疫…",
      title2: "ORDER 6月17日，庆铃&九州通 健康送达千家万户—庆铃五十铃都市冰峰批量交车仪式在九州通医药集团东西湖医药…",
      text: "人民网",
      time: '2023.06.06'
    }, {

      title1: "九州通加码投入升级冷链物流，疫…",
      title2: "ORDER 6月17日，庆铃&九州通 健康送达千家万户—庆铃五十铃都市冰峰批量交车仪式在九州通医药集团东西湖医药…",
      text: "人民网",
      time: '2023.06.06'
    }, {

      title1: "九州通加码投入升级冷链物流，疫…",
      title2: "ORDER 6月17日，庆铃&九州通 健康送达千家万户—庆铃五十铃都市冰峰批量交车仪式在九州通医药集团东西湖医药…",
      text: "人民网",
      time: '2023.06.06'
    }, {

      title1: "九州通加码投入升级冷链物流，疫…",
      title2: "ORDER 6月17日，庆铃&九州通 健康送达千家万户—庆铃五十铃都市冰峰批量交车仪式在九州通医药集团东西湖医药…",
      text: "人民网",
      time: '2023.06.06'
    }, {

      title1: "九州通加码投入升级冷链物流，疫…",
      title2: "ORDER 6月17日，庆铃&九州通 健康送达千家万户—庆铃五十铃都市冰峰批量交车仪式在九州通医药集团东西湖医药…",
      text: "人民网",
      time: '2023.06.06'
    },
  ],
}
