<template>
  <div id="home">
    <!-- 视频 -->
    <div class="banner banner-home">
      <el-carousel indicator-position="inside" arrow="never" trigger="click">
        <el-carousel-item v-for='item in  bannerList' :key="item.title">
          <div class="banner-item">
            <img :src="item.bannerImg">
            <div class="container">
              <div class="title" v-if="item.title">{{ item.title }} </div>
              <div class="text" v-if="item.text">{{ item.text }} </div>
              <div class="apply-use" v-if="item.applyUse" @click="goPlat"></div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <!-- <carousel ref="carousel" :per-page="1" :loop="true" paginationColor="#fff" paginationActiveColor="#1E76F7"
        :minSwipeDistance="60" :autoplay="true" :autoplay-timeout="3000" :center-mode="true">
        <slide v-for='item in  bannerList' :data-index="item.index" :key="item.title">
          <div class="banner-item">
            <img :src="item.bannerImg">
            <div class="container">
              <div class="title" v-if="item.title">{{ item.title }} </div>
              <div class="text" v-if="item.text">{{ item.text }} </div>
            </div>
          </div>
        </slide>
      </carousel> -->
    </div>
    <!-- 主体内容 -->
    <div class="container">
      <div class="floor_1">
        <div class="desc"
          :style="{ background: 'url(' + require('/public/static/image/home_new/resolve.png') + ')  no-repeat' }">
          <div class="item1"> {{ floorOneData.title }} </div>
          <div class="item2">{{ floorOneData.subTitle }}</div>
          <div class="item3"> {{ floorOneData.text }} </div>
          <div class="item4">
            <div class="item4_1" v-for="list  in floorOneData.listData ">
              <div class="left">
                <img :src="list.icon">
              </div>
              <div class="right">
                <div class="text1"> {{ list.text1 }} </div>
                <div class="text2">{{ list.text2 }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="pic_list">
          <div class="item item1" v-for="item  in   floorOneData.picListData ">
            <img :src="item.img1" class="img1">
            <img :src="item.img2" class="img2">
            <div class="list-listContainer">
              <div class="icon">
                <img :src="item.icon">
              </div>
              <div class="title">{{ item.title }}</div>
              <div class="text">{{ item.text }}</div>
            </div>
            <div class="goMore" @click="goResolve(item)">
              <div class="text1">了解更多</div>
              <div class="go">
                <img :src='require("/public/static/image/home_new/gomore.png")'>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="floor_2">
        <div class="floor2_title">全场景的供应链物流产品与服务</div>
        <div class="floor2_title_2">20余年行业深耕，塑造核心技术优势</div>
        <div class="floor2_main">
          <!-- 轮播图标题 -->
          <div class="des">
            <div v-for="(  item, index  ) in   floorTwoData.mainContent  "
              :class="{ item: true, f2_active: currentTowIndex === index }" @click="changeItem(index)">
              <span>{{ item.t1 }}</span>
            </div>
          </div>
          <!-- 轮播图内容 -->
          <div class="main">
            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(  item, index  ) in   floorTwoData.mainContent  " :key="item.t1">
                  <div class="item">
                    <div class="left"> <img :src="item.src" /> </div>
                    <div class="right">
                      <div class="right_item1"> <img :src="item.icon">
                        <div class="text">{{ item.iconTit }} </div>
                      </div>
                      <div class="right_item2"> {{ item.t2 }} </div>
                      <div class="right_item3">
                        <div class="text" v-for=" subItem  in  item.t2List ">
                          <div class="icon"> <img :src='require("/public/static/image/home_new/f2_icon.png")'> </div>
                          <div class="text_1"> {{ subItem }} </div>
                        </div>
                      </div>
                      <div class="right_item4" @click="goProductPage(item)">
                        了解更多
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="floor_3">
        <div class="floor3_title">九州云智物流aPaaS开发平台</div>
        <div class="floor3_title_2">构建敏捷的技术底座，帮助企业伙伴降低数智化门槛、简化研发工作流程</div>
        <div class="floor3_main" @click="goAPass">
          <img src='/static/image/home_new/floor_3.png'>
        </div>
      </div>
      <div class="floor_4"
        :style="{ background: 'url(' + require('/public/static/image/home_new/floor_4_bg.png') + ')  no-repeat' }">
        <div class="floor4_title">服务行业及客户</div>
        <div class="floor4_title_2">帮助逾300个行业客户，实现供应链物流降本增效</div>
        <div class="floor4_main">
          <div v-for=" item  in  this.floorFourData " :key="item.src" class="flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <img :src="item.front_src">
              </div>
              <div class="flip-card-back">
                <img :src="item.back_src">
              </div>
            </div>
          </div>
        </div>
        <div class="tip"> 以上排名不分先后 </div>
      </div>
      <div class="floor_5" :style="bgImg(require('/public/static/image/home_new/floor5_bg.png'))">
        <div class="floor5_title"> 新闻资讯 </div>
        <div class="floor5_title_2">汇聚公司前沿的科技和信息</div>
        <div class="floor5_main">
          <div class="item" v-for="(  item, index  ) in   floorFiveData  " :key="index" @click="toNews(item)">
            <div class="sub-item sub-item_1 single-line" :title="item.title1">{{ item.title1 }}</div>
            <div class="sub-item sub-item_2 multi-line" :title="item.title2">{{ item.title2 }}</div>
            <div class="sub-item sub-item_3">
              <div class="text1">
                {{ item.text }}
              </div>
              九州云智
              <div class="text2"></div>
              <div class="text3">
                {{ item.time }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRecruitData, getNewsData } from "@/request.js"
import { Carousel, Slide } from 'vue-carousel'
import homeConfig from '../assets/config/homeConfig'
export default {
  name: "Home",
  components: {
    Carousel, Slide
  },
  data () {
    return {
      currentHeaderIndex: -1,
      floorOneData: homeConfig.floor1,
      bannerList: homeConfig.bannerList,
      floorTwoData: homeConfig.floorTwoData,
      floorFourData: [],
      floorFiveData: homeConfig.floorFiveData,
      newsData: [], //全部新闻
      filterNews: [], //后三条
      hiddenData: { liName: ["规划集成", "信息系统", "智能装备"] },
      currentHiddIndex: 0,
      currentBannIndex: -1,
      currentOneIndex: 0,
      currentTowIndex: 0,
      currentFourIndex: 0,
      swiperActiveIndex: 3,
    }
  },
  computed: {
    video () {
      return document.getElementById("video")
    },
    desContents () {
      return document.getElementsByClassName("p_4")
    },
  },
  methods: {
    goPlat () {
      this.$router.push('/applicationUse')
    },
    goResolve (data) {
      this.$router.push(data.path)
    },
    goProductPage (data) {
      this.$router.push(`/productServe?index=${data.goMore}`)
      this.$bus.$emit("toContent", data.goMore)
    },
    goAPass () {
      this.$router.push('/cloudPlat')
    },
    //轮播图
    initSwiper () {
      this.swiper = new Swiper(".swiper-container", {
        loop: true,
        preventClicks: false,
        // autoplay: 2000,
        loopedSlides: null,
        spaceBetween: 0,
        centeredSlides: true,
        // slidesPerView: "auto",
        pagination: ".swiper-pagination",
        onSlideChangeEnd: (swiper) => {
          const len = this.floorTwoData.mainContent.length
          this.swiperActiveIndex = swiper.activeIndex
          if (this.swiperActiveIndex > len) {
            this.swiperActiveIndex = this.swiperActiveIndex - len
          }
          if (this.swiperActiveIndex < 1) {
            this.swiperActiveIndex = this.swiperActiveIndex + len
          }
          this.currentTowIndex = this.swiperActiveIndex - 1
        },
      })
    },

    //前一张
    prev () {
      switch (this.swiperActiveIndex) {
        case 3:
          this.swiper.slideTo(8)
          break
        case 4:
          this.swiper.slideTo(3)
          break
        case 5:
          this.swiper.slideTo(4)
          break
        case 6:
          this.swiper.slideTo(5)
          break
        case 1:
          this.swiper.slideTo(6)
          break
        case 8:
          this.swiper.slideTo(1)
          break
        default:
          break
      }
    },

    //后一张
    next () {
      this.swiper.slideNext()
    },

    //title点击切换
    changeItem (index) {
      this.currentTowIndex = index
      this.swiper.slideTo(this.currentTowIndex + 1)
      // switch (index) {
      //   case 0:
      //     this.swiper.slideTo(3)
      //     break
      //   case 1:
      //     this.swiper.slideTo(4)
      //     break
      //   case 2:
      //     this.swiper.slideTo(5)
      //     break
      //   case 3:
      //     this.swiper.slideTo(6)
      //     break
      //   case 4:
      //     this.swiper.slideTo(1)
      //     break
      //   case 5:
      //     this.swiper.slideTo(8)
      //     break
      //   default:
      //     break
      // }
    },

    //第四层样式
    handleStyle (e, index) {
      this.currentFourIndex = index
    },

    //到云市场
    toCloud (index) {
      if (index <= 4) {
        this.$bus.$emit("toContent", index)
        this.$router.push({
          path: "/cloudMarket",
          query: { index },
        })
      } else {
        return false
      }
    },

    //到开发者页面
    toDeveloper (index) {
      this.$bus.$emit("toContent", index)
      this.$router.push({
        path: "/developer",
        query: { index },
      })
    },
    handleNews (data) {
      const arr = data.length > 6 ? data.slice(0, 6) : data
      this.floorFiveData = arr.map(item => {
        return {
          ...item,
          title1: item.news_title,
          title2: item.brief_content,
          text: item.origin,
          time: item.publish_date
        }
      })
    },
    //到新闻页面
    toNews (item) {
      this.$router.push({ path: '/news-details', query: { id: item.news_id, type: item.news_type } })
    },
    async init () {
      const { code, data, message } = await this.http.service({
        // url: '/tdp/data/gateway/v2/news_search/select',   //首页新闻资讯
        // data: {
        //   "news_type": "news"
        // }
        url: '/tdp/data/gateway/v2/news_search_new/select',   //首页新闻资讯
        data: {
          "news_type": "news"
        }
      })
      if (code === 200) {
        this.handleNews(data)
      } else {
        this.$message.error(message)
      }
    },
    GetQueryString (name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i")
      var r = window.location.search.substr(1).match(reg) //获取url中"?"符后的字符串并正则匹配
      var context = ""
      if (r != null)
        context = r[2]
      reg = null
      r = null
      return context == null || context == "" || context == "undefined" ? "" : context
    },
    handleCode (code) {
      if (!code) return
      // if (code.length < 24) return
      localStorage.setItem('wechatCode', code)
      this.$router.push('/applicationUse')
    },
  },

  created () {
    // const code = this.GetQueryString('code')
    // this.handleCode(code)
    this.init()
    // this.newsData = getNewsData()
    // this.filterNews = getNewsData().filter((news, i) => i > 1)
    for (let index = 1; index < 31; index++) {
      this.floorFourData.push({
        front_src: `/static/image/home_new/product_list_${index}.png`,
        back_src: `/static/image/home_new/product_list_${31 - index}.png`,
      })
    }
  },
  mounted () {
    this.initSwiper()
    // const code = this.GetQueryString('code')
    // this.handleCode(code)
  },
}
</script>

<style lang="scss">
.banner-home {
  .el-carousel {
    height: 100%;
    position: relative;
  }

  .el-carousel__container {
    height: 100%;
  }

  .el-carousel__indicators {

    position: absolute;
    bottom: 1rem;

    .el-carousel__button {
      margin-top: 0 !important;
      padding: 0 !important;
      width: .48rem !important;
      height: .07rem !important;
      border-radius: .01rem;
      margin-right: .08rem;
    }

    .is-active {
      .el-carousel__button {
        background: #1E76F7
      }
    }
  }

  .el-carousel__indicator {
    padding: 0;
  }
}
</style>
<style  lang="scss" scoped>
.single-line {
  white-space: nowrap;
  /* 禁止换行 */
  overflow: hidden;
  /* 超出部分隐藏 */
  text-overflow: ellipsis;
  /* 显示省略号 */
}

.multi-line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  /* 控制显示的行数 */
  line-height: .24rem;
  /* 设置行高 */
  max-height: .48rem;
  /* 行高乘以行数，保证显示指定行数的内容 */
}

#home {
  position: relative;
  width: 100%;
  background: #ffffff;
  font-family: Microsoft YaHei;


  .banner {
    position: relative;
    height: 10.8rem;

    .banner-item {
      width: 100%;
      height: 100%;
      position: relative;

      .apply-use {
        position: absolute;
        bottom: 1.9rem;
        left: -.58rem;
        width: 2.3rem;
        height: 0.6rem;
        border-radius: 0.1rem 0.1rem 0.1rem 0.1rem;
        opacity: 1;
        // border: 0.01rem solid #0560D6;
        font-size: 0.24rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #0560D6;
        text-align: center;
        line-height: 0.54rem;
        cursor: pointer;
      }

      img {
        width: 100%;
        height: 100%;
      }

      .container {
        position: absolute;
        left: 3rem;
        bottom: 2rem;

        .title {
          height: .74rem;
          font-size: .56rem;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #FFFFFF;
          line-height: .74rem;
          margin-bottom: .24rem;
        }

        .text {
          height: .4rem;
          font-size: .28rem;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: .4rem;
        }
      }


    }
  }

  .container {
    [class^="floor_"] {
      /* padding-top: 1.08rem; */

      [class$="_title"] {
        text-align: center;
        font-size: 0.4rem;
        font-weight: 400;
        color: #383838;

        [class$="_t1"] {
          font-size: 0.4rem;
          color: #232323;
        }

        [class$="_t2"] {
          height: 0.2rem;
          margin-top: 0.32rem;
          font-size: 0.18rem;
          font-weight: 400;
          color: #666666;
        }
      }
    }

    .floor_1 {
      padding-top: 1.08rem;


      .desc {
        justify-content: space-around;
        width: 100%;
        height: 100%;
        height: 6.43rem;
        background-size: cover;
        padding: 0 2.74rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-size: cover;
        /* 背景图片等比例缩放并填满容器 */
        background-position: center;
        /* 将背景图片居中 */

        .item1 {
          font-weight: bold;
          color: #0A121F;
          font-size: .4rem;
        }

        .item2 {
          color: #9AA3B2;
          font-size: .28rem;
          margin-top: .28rem;
        }

        .item3 {
          margin-top: .8rem;
          font-weight: 500;
          color: #565E66;
          font-size: .2rem;
          line-height: .36rem;
        }

        .item4 {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-top: .55rem;
          margin-bottom: 1.08rem;

          .item4_1 {
            display: flex;
            align-items: center;

            .left {
              width: .72rem;
              height: .72rem;
              margin-right: .24rem;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .right {
              .text1 {
                height: .56rem;
                font-size: .4rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #0560D6;
                line-height: .56rem;
              }

              .text2 {
                height: .3rem;
                font-size: .22rem;
                font-family: MicrosoftYaHei;
                color: #565E66;
                line-height: .3rem;
              }
            }

          }
        }
      }

      .pic_list {
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 6.4rem;

        .item {
          flex: 1;
          position: relative;

          .img1 {
            position: relative;
            width: 100%;
            height: 100%;
            display: block;
          }

          .img2 {
            position: relative;
            width: 100%;
            height: 100%;
            display: none;
          }

          transition:flex 0.2s linear;

          .list-listContainer {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            z-index: 9;

            .icon {
              position: absolute;
              top: 1.3rem;
              text-align: center;
              width: 100%;

              img {
                width: .5rem;
                height: .5rem;
              }
            }

            .title {
              width: 100%;
              position: absolute;
              top: 1.95rem;
              text-align: center;
              font-size: .32rem;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #FFFFFF;
              line-height: .45rem;
            }

            .text {
              width: 100%;
              position: absolute;
              top: 1.71rem;
              padding: 0 .4rem;
              font-size: .2rem;
              font-family: PingFang-SC-Medium, PingFang-SC;
              font-weight: 500;
              color: #FFFFFF;
              line-height: .36rem;
              display: none;
            }
          }

          .goMore {
            position: absolute;
            bottom: 1.3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 10;
            width: 100%;
            font-size: .2rem;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
            color: #FFFFFF;
            cursor: pointer;

            .go {
              width: .4rem;
              height: .4rem;
              margin-left: .08rem;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }

        .item::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.7);
          z-index: 1;
        }

        .item:hover {
          flex: 3;

          .img1 {
            display: none;
          }

          .img2 {
            display: block;
          }

          .list-listContainer {
            .icon {
              display: none;
            }

            .title {
              top: 1.1rem;
            }

            .text {
              display: block;
            }
          }
        }

        .item:hover::before {
          flex: 3;
          background-color: rgba(0, 0, 0, 0.4);
        }
      }

    }

    .floor_2 {
      padding-top: 1.08rem;
      position: relative;
      height: 8.64rem;
      background: #F6FBFF;

      .floor2_title {
        font-size: .4rem;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #0A121F;
      }

      .floor2_title_2 {
        margin-top: .28rem;
        font-size: .28rem;
        font-family: MicrosoftYaHei;
        color: #9AA3B2;
        text-align: center;
      }

      .floor2_main {
        .des {
          position: relative;
          z-index: 2;
          display: flex;
          padding-top: 0.8rem;
          border-bottom: 0.02rem solid #E3EDF9;

          .item {
            height: 104%;
            font-size: .24rem;
            font-weight: bold;
            height: 0.6rem;
            color: #0A121F;
            cursor: pointer;
          }

          .item:nth-child(1) {
            margin-left: 4.44rem;
          }

          .item:nth-child(n + 2) {
            margin-left: 1.3rem;
          }
        }

        .main {
          .item {
            margin-top: 0.48rem;
            display: flex;
            width: 100%;
            height: 5.06rem;

            .left {
              width: 9.3rem;
              height: 3.5rem;
              margin-right: .7rem;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .right {
              position: relative;
              height: 3.5rem;

              .right_item1 {
                display: flex;
                align-items: center;
                font-weight: bold;
                color: #0A121F;
                font-size: .24rem;
                margin-bottom: .28rem;

                img {
                  width: .44rem;
                  height: .44rem;
                  margin-right: .16rem;
                }
              }

              .right_item2 {
                font-weight: normal;
                width: 6.61rem;
                font-size: .16rem;
                font-family: PingFang-SC-Bold, PingFang-SC;
                font-weight: bold;
                color: #565E66;
                line-height: .28rem;
                margin-bottom: .24rem;
              }

              .right_item3 {
                .text {
                  display: flex;
                  align-items: center;

                  .icon {
                    width: .16rem;
                    height: .16rem;
                    margin-right: .12rem;

                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }

                  .text_1 {
                    font-size: .16rem;
                    font-family: PingFang-SC-Bold, PingFang-SC;
                    font-weight: bold;
                    color: #9AA3B2;
                    line-height: .28rem;
                  }
                }
              }

              .right_item4 {
                position: absolute;
                bottom: 0;
                min-width: 1.12rem;
                padding: .1rem .2rem;
                background: #FFFFFF;
                border-radius: .02rem;
                border: .01rem solid #1E76F7;
                color: #1E76F7;
                cursor: pointer;
              }

              .right_item4:hover {
                background-color: #1E76F7;
                color: #fff;
              }
            }
          }

          .common_pagination {
            display: inline-flex;
            margin-left: 8.83rem;
            margin-top: 0.3rem;

            span {
              width: 0.14rem;
              height: 0.04rem;
              background: #ffffff;
              opacity: 0.5;
            }

            span:nth-of-type(n + 2) {
              margin-left: 0.12rem;
            }
          }

          .prev,
          .next {
            position: absolute;
            top: 5.57rem;
            z-index: 3;
            width: 0.62rem;
            height: 0.62rem;
            background: #ffffff;
            box-shadow: 0rem 0rem 0.24rem 0rem rgba(186, 186, 186, 0.75);
            border-radius: 50%;
            cursor: pointer;
            text-align: center;
            line-height: 0.62rem;

            .icon-zuo,
            .icon-you {
              font-size: 0.24rem;
              color: gray;
            }
          }

          .prev {
            left: 1.9rem;
          }

          .next {
            right: 1.9rem;
          }
        }
      }

      .f2_cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        box-shadow: 0rem 0 12rem 0.5rem rgba(0, 0, 0, 0.7) inset;
        pointer-events: none;
      }
    }

    .floor_3 {
      padding-top: 1.08rem;
      position: relative;
      height: 11.09rem;

      .floor2_title {
        font-size: .4rem;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #0A121F;
      }

      .floor3_title_2 {
        margin-top: .28rem;
        font-size: .28rem;
        font-family: MicrosoftYaHei;
        color: #9AA3B2;
        text-align: center;
      }

      .floor3_main {
        margin-top: .8rem;
        width: 15.14rem;
        height: 7.12rem;
        margin-left: 2rem;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .floor_4 {
      padding: 1.08rem 0;
      position: relative;
      background-size: cover;
      z-index: 1;

      .floor4_title {
        font-size: .4rem;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #0A121F;
      }

      .floor4_title_2 {
        margin-top: .28rem;
        font-size: .28rem;
        font-family: MicrosoftYaHei;
        color: #9AA3B2;
        text-align: center;
      }

      .floor4_main {
        margin-top: .8rem;
        padding: 0 2.6rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .flip-card:hover .flip-card-inner {
          transform: rotateY(180deg);
        }

        .flip-card {
          width: 1.84rem;
          height: .8rem;
          padding: .12rem;
          margin-right: .5rem;
          margin-bottom: .4rem;
          background: #FFFFFF;
          box-shadow: .02rem .02rem .08rem 0 rgba(197, 221, 255, 0.5);
          border-radius: .08rem;

          .flip-card-inner {
            width: 100%;
            height: 100%;
            transition: transform 0.6s;
            transform-style: preserve-3d;
            position: relative;
          }


          .flip-card-front,
          .flip-card-back {
            width: 100%;
            height: 100%;
            position: absolute;
            backface-visibility: hidden;
          }

          .flip-card-back {
            transform: rotateY(180deg);
          }

          img {
            width: 100%;
            height: 100%;
          }
        }

        .flip-card:nth-child(6n) {
          margin-right: 0;
        }
      }

      .tip {
        height: .27rem;
        font-size: .2rem;
        font-family: MicrosoftYaHei;
        color: #9AA3B2;
        line-height: .27rem;
        text-align: center;
      }
    }

    .floor_5 {
      padding-top: 1.08rem;
      height: 8.73rem;
      background-size: cover;
      color: #ffffff;

      .floor5_title {
        font-size: .4rem;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #ffffff;

      }

      .floor5_title_2 {
        margin-top: .28rem;
        font-size: .28rem;
        font-family: MicrosoftYaHei;
        text-align: center;
      }

      .floor5_main {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        z-index: 10;
        margin: 0 auto;
        margin-top: 0.8rem;
        padding: 0 2.6rem;
        /* height: 4.26rem;
        width: 14.21rem; */

        .item {
          width: 4.42rem;
          height: 2.22rem;
          margin-right: .32rem;
          margin-bottom: .32rem;
          background: linear-gradient(180deg, #181E2B 0%, #29354C 100%);
          background-size: cover;
          /* 将背景图等比例缩放并铺满容器 */
          cursor: pointer;
          padding: .48rem .24rem;

          .sub-item_1 {
            font-size: .22rem;
            font-family: MicrosoftYaHei;
            color: #FFFFFF;
            line-height: .3rem;
            margin-bottom: .16rem;
            width: 3.97rem;
          }

          .sub-item_2 {
            font-size: .14rem;
            font-family: MicrosoftYaHei;
            color: #FFFFFF;
            line-height: .24rem;
            width: 3.97rem;
            margin-bottom: .16rem;
            opacity: 0.7;
          }

          .sub-item_3 {
            display: flex;
            font-size: .14rem;
            font-family: MicrosoftYaHei;
            color: #FFFFFF;
            line-height: .16rem;

            .text2 {
              margin: 0 .16rem;
              width: .01rem;
              background-color: #fff;
            }
          }

          &:hover {
            background: url("../assets/image/home_new/floor5_item_bg.png") no-repeat;
            background-size: cover;
          }
        }

        .item:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }
}

.f2_active {
  border-bottom: 0.04rem solid #1562ec;
}

.swiper-slide {
  width: 12.78rem;
  height: 5.28rem;
}
</style>
